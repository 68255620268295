import { Drawer, List, ListItem, ListItemText } from '@mui/material'
import React from 'react'

function CustomDrawer({ open, handleDrawerClose, handleContactUsClick }) {
  const onClickHome = () => {
    window.location.href = '/'
  }
  const onClickContactUs = () => {
    console.log('contact us')
    handleContactUsClick()
    handleDrawerClose()
  }

  return (
    <Drawer anchor="left" open={open} onClose={handleDrawerClose}>
      <List>
        <ListItem component="div">
          <ListItemText primary="Home" onClick={onClickHome} />
        </ListItem>
        <ListItem component="div">
          <ListItemText primary="Contact Us" onClick={onClickContactUs} />
        </ListItem>
      </List>
    </Drawer>
  )
}

export default CustomDrawer
