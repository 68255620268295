import {
  Box,
  CardContent,
  ThemeProvider,
  Typography,
  createTheme,
  useMediaQuery,
} from '@mui/material'
import Grid from '@mui/material/Grid'
import Navbar from './component/Navbar.jsx'
import Card from './component/Card.jsx'
import React, { useEffect, useState } from 'react'
import ProductFooter from './component/ProductFooter.jsx'

function App() {
  const [darkMode, setDarkMode] = useState(false)
  const [urlFinders, setUrlFinders] = useState([])
  const [dataLoaded, setDataLoaded] = useState(false) // New state
  const [otherPage, setOtherPage] = useState(false) // New state
  const mobileScreen = useMediaQuery('(max-width:600px)')

  const handleContactUsClick = () => {
    console.log('Contact Us clicked')
    setUrlFinders([])
    setOtherPage(true)
  }

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
  })

  const handleThemeChange = () => {
    setDarkMode(!darkMode)
  }

  const onClickChange = (event) => {
    setUrlFinders(null)
    setDataLoaded(false)
    setOtherPage(false)
    fetch(
      `https://referalsystem-ujpu.onrender.com/referral/system/search?searchString=${event.textFieldValue}&count=8`
    )
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        setUrlFinders(data)
        setDataLoaded(true)
      })
  }

  useEffect(() => {
    const onPageLoad = () => {
      fetch(
        `https://referalsystem-ujpu.onrender.com/referral/system/search?searchString=trending&count=8`
      )
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          setUrlFinders(data)
          setDataLoaded(true)
        })
    }
    onPageLoad()
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Box
        style={{
          backgroundColor: darkMode ? 'rgb(38, 38, 38)' : 'rgb(242, 242, 242)',
        }}
      >
        <Grid container style={{ position: 'relative' }}>
          <Grid item xs={12} sm={12}>
            <Navbar
              onThemeChange={handleThemeChange}
              darkMode={darkMode}
              onClickChange={onClickChange}
              handleContactUsClick={handleContactUsClick}
            />
          </Grid>
        </Grid>
        {!otherPage ? (
          <div
            style={{
              overflow: 'auto',
              maxHeight: 'calc(100vh - 75px)',
              scrollbarWidth: 'thin',
              scrollbarColor: 'transparent transparent',
            }}
          >
            <Grid container>
              <Grid item xs={0} sm={1}></Grid>
              <Grid item xs={12} sm={10}>
                <Card
                  dataLoaded={dataLoaded}
                  urlFinders={urlFinders}
                  onClickChange={onClickChange}
                  otherPage={otherPage}
                  darkMode={darkMode}
                />
                {dataLoaded && <ProductFooter darkMode={darkMode} />}
              </Grid>
              <Grid item xs={0} sm={1}></Grid>
            </Grid>
          </div>
        ) : (
          <Grid
            container
            justifyContent="center"
            alignContent='flex-start'
            style={{height:'85vh', margin: '15px' }}
          >
            <CardContent
              style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)' }}
            >
              <ThemeProvider theme={theme}>
                <Typography
                  variant="h5"
                  component="h2"
                  color={darkMode ? 'textPrimary' : 'initial'}
                >
                  Contact Us
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  color={darkMode ? 'textPrimary' : 'initial'}
                >
                  Email: careatsearchgiftdotin@gmail.com
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  color={darkMode ? 'textPrimary' : 'initial'}
                >
                  Mobile No: +91 XXXXXXXXX
                </Typography>
              </ThemeProvider>
            </CardContent>
          </Grid>
        )}
      </Box>
    </ThemeProvider>
  )
}

export default App
