import { Divider, Grid, Link, Stack, Typography } from '@mui/material'
import React from 'react'

function ProductFooter({ darkMode }) {
  return (
    <>
      <Grid
        container
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          marginTop: 2,
          color: darkMode ? 'white' : 'black',
        }}
      >
        <Link
          href="/"
          sx={{ color: 'inherit', textDecoration: 'none', marginRight: 1 }}
        >
          HOME
        </Link>
        <Divider orientation="vertical" flexItem sx={{ marginX: 1 }} />
        <Link
          href="mailto:careatsearchgiftdotin@gmail.com"
          sx={{ color: 'inherit', textDecoration: 'none' }}
        >
          Contact Us
        </Link>
        <div></div>
      </Grid>
      <Grid
        container
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          marginTop: 2,
          color: darkMode ? 'white' : 'black',
        }}
      >
        <Typography variant="body2" style={{ fontSize: '12px' }}>
          © 2024 SearchGift
        </Typography>
      </Grid>
    </>
  )
}

export default ProductFooter
