import React from 'react'
import {
  Card,
  CardContent,
  Chip,
  Typography,
  useMediaQuery,
  ImageList,
  Avatar,
} from '@mui/material'
import styled from 'styled-components'
import ImageNotFound from '../images/imagenotfound.png'
import icon from '../images/icon.PNG'
import { keyframes } from '@emotion/react'
import iconDark from '../images/iconDark.png'

const StyledImage = styled.img`
  width: 100%;
  height: 200px; /* Set the desired fixed height for all images */
  object-fit: cover; /* This property ensures that the image maintains its aspect ratio while covering the specified height and width */
  border-radius: 10px;
  transition: transform 0.3s;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
    z-index: 1;
  }
`

export default function TitlebarImageList({
  dataLoaded,
  urlFinders,
  darkMode,
}) {
  const matches = useMediaQuery('(max-width:600px)')
  const padding = matches ? 2 : 2
  const [hoveredItem, setHoveredItem] = React.useState(null)
  const spinAnimation = keyframes`
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
`
  if (dataLoaded === false) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: matches?'80vh':'100vh',
        }}
      >
        <Avatar
          alt="Loading content"
          src={darkMode ? iconDark : icon}
          sx={{
            width: matches ? '150px' : '200px',
            height: '150px',
            animation: `${spinAnimation} 2s linear infinite`,
          }}
        />
      </div>
    )
  }
  if (urlFinders == null || urlFinders.length === 0) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={ImageNotFound} alt="Not found" />
      </div>
    )
  }

  return (
    <ImageList cols={matches ? 2 : 4} sx={{ padding }}>
      {urlFinders.map((item, index) => (
        <Card
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          }}
          onMouseEnter={() => setHoveredItem(index)}
          onMouseLeave={() => setHoveredItem(null)}
          onTouchStart={() => setHoveredItem(index)}
          onTouchEnd={() => setHoveredItem(null)}
        >
          <a
            href={item.productUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
              color: darkMode ? 'white' : 'black',
            }}
          >
            <CardContent
              style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
            >
              <StyledImage
                srcSet={`${item.imageUrl}`}
                src={`${item.imageUrl}`}
                alt={`${item.description}`}
                loading="lazy"
              />
              <div style={{ marginTop: '15px' }}>
                <Chip
                  sx={{ color: 'orange' }}
                  label={`₹${item.price}`}
                  variant="orange"
                />
              </div>
              <Typography variant="body2" component="p" className="hover-text">
                {hoveredItem === index
                  ? item.title
                  : `${item.title.substring(0, 40)}...`}
              </Typography>
            </CardContent>
          </a>
        </Card>
      ))}
    </ImageList>
  )
}
