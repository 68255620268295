import React, { useState } from 'react'
import { keyframes } from '@emotion/react'
import {
  AppBar,
  Toolbar,
  Switch,
  Avatar,
  useMediaQuery,
  Button,
  TextField,
} from '@mui/material'
import icon from '../images/icon.PNG'
import iconDark from '../images/iconDark.png'
import SearchIcon from '@mui/icons-material/Search'
import CustomDrawer from './CustomDrawer'
const Navbar = ({
  onThemeChange,
  darkMode,
  onClickChange,
  handleContactUsClick,
}) => {
  const [open, setOpen] = React.useState(false)
  const [textFieldValue, setTextFieldValue] = useState('')
  const matches = useMediaQuery('(max-width:600px)')
  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const spinAnimation = keyframes`
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
`

  return (
    <React.Fragment>
      <AppBar
        position="static"
        sx={{
          backgroundColor: darkMode ? 'rgb(38, 38, 38)' : 'rgb(0, 204, 153)',
          boxShadow: darkMode
            ? '0px 2px 8px rgba(0, 0, 0, 0.5)'
            : '0px 2px 8px rgba(0, 128, 0, 0.5)',
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Avatar
            alt="Search Gift Using AI"
            src={darkMode ? iconDark : icon}
            onClick={handleDrawerOpen}
            sx={{
              width: matches ? '50px' : '80px',
              height: '75px',
              animation: `${spinAnimation} 2s linear infinite`,
            }}
          />

          <TextField
            value={textFieldValue}
            label="Search Using AI"
            sx={{
              transition: 'transform 0.3s',
              '&:hover': {
                transform: 'scale(1.02)',
              },
              width: matches ? '70%' : '86%',
            }}
            onChange={(event) => setTextFieldValue(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                onClickChange({ textFieldValue })
              }
            }}
            InputProps={{
              endAdornment: (
                <Button
                  variant="solid"
                  color="secondary"
                  endIcon={<SearchIcon />}
                  onClick={() => onClickChange({ textFieldValue })}
                >
                  {matches ? '' : 'Search'}
                </Button>
              ),
            }}
          />
          <CustomDrawer
            open={open}
            handleDrawerClose={handleDrawerClose}
            handleContactUsClick={handleContactUsClick}
          />
          <Switch checked={darkMode} onChange={onThemeChange} />
        </Toolbar>
      </AppBar>
    </React.Fragment>
  )
}

export default Navbar
